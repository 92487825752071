html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
}
body {
  margin: 0;
}
main {
  display: block;
}
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
pre {
  font-family: monospace, monospace;
  font-size: 1em;
}
a {
  background-color: transparent;
}
abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}
b,
strong {
  font-weight: bolder;
}
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}
small {
  font-size: 80%;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
img {
  border-style: none;
}
button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}
fieldset {
  padding: 0.35em 0.75em 0.625em;
}
legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}
progress {
  vertical-align: baseline;
}
textarea {
  overflow: auto;
}
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}
details {
  display: block;
}
summary {
  display: list-item;
}
template {
  display: none;
}
[hidden] {
  display: none;
}
/* You can add global styles to this file, and also import other style files */
@font-face {
  font-family: "Golos"; // TextWebDemiBold semibold?
  src: url("/assets/fonts/golos-text_demibold.woff2") format("woff2"),
    url("/assets/fonts/golos-text_demibold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Golos"; // TextWebMedium
  src: url("/assets/fonts/golos-text_medium.woff2") format("woff2"),
    url("/assets/fonts/golos-text_medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Golos"; // TextWebRegular
  src: url("/assets/fonts/golos-text_regular.woff2") format("woff2"),
    url("/assets/fonts/golos-text_regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Golos"; // TextWebRegular
  src: url("/assets/fonts/golos-text_bold.woff2") format("woff2"),
    url("/assets/fonts/golos-text_bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@import "./assets/css/_export-variable";

body.hide {
  overflow: hidden;
}

:root {
  /* color main */
  --Blue-Light: #007ed3;
  --Blue-Normal: #00418e;
  --Blue-Dark: #002c60;
  --Pink: #be00fc;

  /* color secondary */
  --Dark: #0e0e0e;
  --600: #375f8e;
  --500: #597ba2;
  --400: #91a5c0;
  --300: #acbbd0;
  --200: #c7d1df;
  --100: #e3e8ef;
  --White: #fff;
  --Background: #e3f8ff;

  /* color gradient */
  --Gradient-1: linear-gradient(180deg, #00418e 0%, #007ed3 100%);
  --Gradient-2: linear-gradient(106deg, #1efafc 7.72%, #be00fc 89.18%);

  /* color system */
  --System-Red: #ff6f89;
  --System-Green: #16ac75;
  --System-Orange: #ffb06e;
}

body {
  background-color: #c4ebf8;
  font-family: "Golos", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 10px;
  color: var(--600);
  min-width: 380px;
  min-height: 100vh;
  @include text-l;
}

strong {
  color: var(--Blue-Dark);
}

app-root {
  height: 100%;
  display: block;
}

$mediaMobileMax: 1024px;

.h1,
h1 {
  font-family: "Golos", sans-serif;
  font-size: 54px;
  font-style: normal;
  font-weight: 600;
  line-height: 111%;
  text-transform: uppercase;
  @media (max-width: $mediaMobileMax) {
    font-size: 32px;
    line-height: 125%;
  }
}

.h2,
h2 {
  font-family: "Golos", sans-serif;
  font-size: 46px;
  font-style: normal;
  font-weight: 600;
  line-height: 130.435%;
  text-transform: uppercase;
  @media (max-width: $mediaMobileMax) {
    font-size: 28px;
    line-height: 128.571%;
  }
}

.h3,
h3 {
  text-transform: none;
  font-family: "Golos", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
  @media (max-width: $mediaMobileMax) {
    font-size: 24px;
  }
}

.h4,
h4 {
  text-transform: none;
  font-family: "Golos", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
  @media (max-width: $mediaMobileMax) {
    font-size: 20px;
    line-height: 140%;
  }
}

h1,
h2,
h3,
h4 {
  margin: 0;
}

.text-xl {
  font-family: "Golos", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 155.556%;
}

.text-l {
  font-family: "Golos", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.text-m {
  font-family: "Golos", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 142.857%;
}

.text-s {
  font-family: "Golos", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.button-s {
  font-family: "Golos", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 200%;
  letter-spacing: 0.24px;
  text-transform: uppercase;
}

a {
  text-decoration: none;
  color: inherit;
  &:hover {
  }
}

.h1 {
}

h2,
h3,
h4 {
  color: var(--Blue-Dark, #002c60);
}

.content-wrapper {
  max-width: 1256px;
  position: relative;
  z-index: 2;
  padding: 0 16px;
  box-sizing: border-box;
  width: 100%;
  margin: 0 auto;
}

.wrapper--small {
  max-width: 808px;
  margin: 0 auto;
  width: 100%;
}

.content-wrapper--middle {
  max-width: 1015px;
  width: 100%;
  margin: 0 auto;
}

.icon-play {
  width: 24px;
  height: 24px;
  background-image: url("./assets/img/video-play.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

.decoration {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    pointer-events: none;
    top: var(--decoration-top-position, 0);
    height: var(--decoration-height, 650px);
    left: 50%;
    transform: translateX(-50%);
    min-width: 1440px;
    width: 100vw;
    background-image: var(
      --decoration-bg,
      url("./assets/img/decoration/right_01.svg")
    );
    background-repeat: no-repeat;
    background-position: var(--decoration-alignment, right) center;
    background-size: contain;
  }
  &--position {
    &-right {
      --decoration-alignment: right;
    }
    &-left {
      --decoration-alignment: left;
    }
  }
  &--image {
    &-right {
      &-1 {
        --decoration-bg: url("./assets/img/decoration/right_01_rotate.svg");
        --decoration-height: 650px;
        --decoration-alignment: calc(100% + 340px);
      }
      &-2 {
        --decoration-bg: url("./assets/img/decoration/right_02_rotate.svg");
        --decoration-height: 563px;
        --decoration-alignment: calc(100% + 400px);
      }
      &-3 {
        --decoration-bg: url("./assets/img/decoration/right_03_rotate.svg");
        --decoration-height: 580px;
        --decoration-alignment: calc(100% + 409px);
      }
    }
    &-left {
      &-1 {
        --decoration-bg: url("./assets/img/decoration/left_01_rotate.svg");
        --decoration-height: 620px;
        --decoration-alignment: -400px;
      }
    }
  }
}

.decorationFull {
  position: relative;
  &::after {
    content: "";
    position: absolute;
    pointer-events: none;
    top: 0;
    height: 100%;
    left: 50%;
    transform: translateX(-50%);
    min-width: 1440px;
    width: 100vw;
    background: url("./assets/img/decoration/right_02_rotate.svg") no-repeat
        calc(100% + 400px) 75px,
      url("./assets/img/decoration/right_01_rotate.svg") no-repeat
        calc(100% + 400px) 1538px,
      url("./assets/img/decoration/left_01_rotate.svg") no-repeat -400px 695px;
    background-size: 600px;
  }
}

.auth-title-section {
  margin-top: 0;
  margin-bottom: 40px;
}

.auth-title-section-modal {
  margin-top: 0;
  margin-bottom: 0;
}

.field-list {
  margin: 0 auto;
  padding: 0;
  max-width: 508px;
  &__item {
    padding: 0;
    margin: 0 0 24px;
    display: block;
  }
  &__item:not(:last-child) {
    margin-bottom: 24px;
  }
}

.field {
  margin-bottom: 24px;
}
.field-input {
  margin-bottom: 24px;
  position: relative;
  .label {
    position: absolute;
    left: 0;
    bottom: 1px;
    top: 0;
    padding: 12px 8px 12px 0;
    @include text-l();
    &-inner {
      width: 128px;
      box-sizing: border-box;
    }
  }
  @media (max-width: 749px) {
    .label {
      position: static;
      margin: 0;
      padding: 0;
    }
    input {
      padding-left: 0;
    }
  }
}

.input {
  @include text-l();
  background-color: transparent;
  outline: none;
  display: block;
  width: 100%;
  border: 0;
  padding: 12px 8px 12px 128px;
  border-bottom: 1px solid var(--200);
  box-sizing: border-box;
  border-radius: 0;
  &:focus,
  &:active {
    border-color: var(--Blue-Light);
  }
  &::placeholder {
    @include text-l();
    /* Firefox, Chrome, Opera */
    text-align: left;
    color: var(--300);
  }
}

.field-checkbox {
  label {
    display: block;
    position: relative;
    cursor: pointer;
    input {
      opacity: 0;
      visibility: hidden;
      position: absolute;
      & + .caption {
        padding-left: 40px;
        position: relative;
        @include text-m();
        color: var(--400);
        line-height: 24px;
        display: inline-block;
        vertical-align: top;
        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 24px;
          height: 24px;
          border-radius: 8px;
          border: 1px solid var(--300);
          background-color: #fff;
          background-position: 50% 50%;
          box-sizing: border-box;
        }
      }
      &:checked + .caption::before {
        background-color: var(--Blue-Light);
        border-color: var(--Blue-Light);
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0IiBmaWxsPSJub25lIj4NCjxwYXRoIGQ9Ik03IDExLjVMMTAuNSAxNUwxNyA4LjUiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPg0KPC9zdmc+DQo=");
      }
    }
  }
}

/// layout

.dict-layout {
  padding: 40px;
  position: relative;
  background-color: #fff;
  border-radius: 8px;
  max-width: 808px;
  margin: 0 auto 64px;
  box-sizing: border-box;
  &--partners {
    padding: 40px;
    position: relative;
    .caption {
      @include text-xl();
      margin: 0 !important;
      font-weight: bold;
      color: var(--Blue-Dark);
    }
    .logo {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      max-width: 243px;
    }
  }
  .wrapper {
    & > .caption {
      margin: 0 0 20px;
    }
    .content-question {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      @include text-xl();
      .content {
        flex: 2 0 350px;
        p:first-child {
          margin-top: 0;
        }
      }
      img {
        margin-left: 10px;
      }
      .image {
        flex: 0 0 350px;
      }
      .label-info {
        max-width: 221px;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        background: #be00fc;
        border-radius: 8px;
        padding: 8px;
        color: #fff;
        gap: 8px;
        box-sizing: border-box;
        position: absolute;
        right: 40px;
        top: 0;
        transform: translateY(-50%);
        .icon {
          flex: 0 0 24px;
          width: 24px;
          height: 24px;
          margin: -2px 0;
          display: block;
          background-image: url("./assets/img/dictant-icon-info.svg");
        }
        .caption {
          @include text-m();
        }
      }
    }
  }
  .question-entry {
    margin-top: 64px;
    margin-bottom: 64px;
  }
  @media (max-width: 749px) {
    padding: 24px;
    &--partners {
      padding-bottom: 0;
      .wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      }
      .logo {
        position: static;
        transform: none;
      }
    }
    .wrapper {
      & > .caption {
        margin: 0 0 16px;
      }
      .content-question {
        display: flex;
        flex-direction: column;
        @include text-l();
        .content {
          flex: 100%;
        }
        img {
          margin-left: 0;
        }
        .image {
          margin-left: 0;
          flex: 100%;
          img {
            max-width: 100%;
          }
        }
        .label-info {
          margin-top: 24px;
          transform: none;
          position: static;
        }
      }
    }
  }
}

.action-submit-visual {
  display: inline-block;
  vertical-align: top;
  cursor: pointer;
  border: 0;
  border-radius: 6px;
  padding: 22px 32px;
  color: var(--White);
  background: var(--Blue-Light);
  text-transform: uppercase;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 100% */
  letter-spacing: 0.6px;
  &:hover {
    background: var(--Blue-Normal, #00418e);
  }
  &:active {
    background: var(--Gradient-1);
  }
  &.disabled {
    background-color: var(--100);
    color: var(--300);
  }
}

body .bvi-body {
  ui-button button {
    border-radius: 0 !important;
    background-color: #fff !important;
  }
  app-page-main .next-block {
    display: none;
  }
  .main-slider {
    padding-bottom: 60px;
    &::before {
      content: none;
    }
  }
  app-partner-slider .embla__image {
    &::before {
      content: none;
    }
    img {
      border-radius: 50%;
      border: 2px solid #000;
      transition: none;
    }
    &:hover {
      img {
        transform: none !important;
      }
    }
  }
  app-advantages-gpage {
    .video {
      display: none;
    }
    .content-wrapper {
      padding-bottom: 0;
    }
    .content {
      height: inherit;
    }
    .advantages-list {
      padding-top: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 20px;
      flex-direction: column;
      flex-wrap: wrap;
      margin: 0 !important;
      .advantages-item {
        position: static !important;
        height: auto !important;
        width: auto !important;
        background-image: none !important;
        .advantages-entry {
          gap: 10px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          .caption br {
            display: none;
          }
        }
      }
    }
  }
  .decoration::before {
    content: none;
  }
  .decorationFull::after {
    content: none;
  }
  .footer {
    border-top: 4px solid #000;
    padding-top: 40px;
    background-color: var(--Blue-Dark);
    &::before {
      content: none;
    }
  }
  app-event-card {
    .event .event__wrapper .description {
      display: block;
      position: static;
      opacity: 1;
      visibility: visible;
      padding: 0;
    }
  }
  .partners-list {
    a {
      display: block;
    }
  }

  .question-section {
    .question-head__wrapper {
      max-width: inherit !important;
      text-align: center;
      width: 100%;
      margin: 0 0 60px 0;
    }
    .section__body {
      display: block !important;
    }
    .question-head__wrapper::before {
      content: none !important;
    }
  }
  .review-list .embla__slide {
    flex: 0 0 100% !important;
  }
  app-review-item {
    .review-item__wrapper {
      background: #fff;
      transform: none !important;
      &::before {
        content: none;
      }
    }
  }
  app-frame-page {
    .advantages {
      display: block;
      justify-content: flex-start;
      .item {
        height: auto;
        margin-top: 40px;
      }
      .item__wrapper {
        height: auto !important;
        background-image: none !important;
        br {
          display: none;
        }
      }
    }
  }
  ui-input {
    .label {
      position: static !important;
    }
    input {
      padding-left: 0 !important;
    }
  }
  .header .actions .icon {
    display: none;
  }
  &[data-bvi-theme="black"] .header__logo::before {
    background-image: url("assets/img/logo-main.svg") !important;
  }

  &[data-bvi-theme="black"] .projects-list .projects-list__item img {
    background-color: #fff !important;
  }

  &[data-bvi-theme="white"] .main.headerNotFix .header__logo::before {
    background-image: url("assets/img/logo-dark.svg") !important;
  }

  app-dictant-main {
    .progress-time {
      .time {
        font-size: 125% !important;
      }
      svg {
        display: none;
      }
    }
  }
  iframe#my-player {
    display: block !important;
  }
}

html body .bvi-panel.bvi-fixed-top {
  position: static !important;
}


@import "@ng-select/ng-select/themes/default.theme.css";


body ng-select.ng-select.ng-select-single .ng-select-container {
  padding: 10px 24px;
  height: auto;
  input {
    opacity: 0;
  }
}
