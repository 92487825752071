$mediaMobileMax: 1024px;

.h1 {
  font-family: 'Golos', sans-serif;
  font-size: 54px;
  font-style: normal;
  font-weight: 600;
  line-height: 111%;
  text-transform: uppercase;
  @media (max-width: $mediaMobileMax) {
    font-size: 32px;
    line-height: 125%;
  }
}


.h2 {
  font-family: 'Golos', sans-serif;
  font-size: 46px;
  font-style: normal;
  font-weight: 600;
  line-height: 130.435%;
  text-transform: uppercase;
  @media (max-width: $mediaMobileMax) {
    font-size: 28px;
    line-height: 128.571%;
  }
}


.h3 {
  font-family: 'Golos', sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
  @media (max-width: $mediaMobileMax) {
    font-size: 24px;
  }
}


.h4 {
  font-family: 'Golos', sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
  @media (max-width: $mediaMobileMax) {
    font-size: 20px;
    line-height: 140%;
  }
}


.text-xl {
  font-family: 'Golos', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 155.556%;
}


.text-l {
  font-family: 'Golos', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.text-m {
  font-family: 'Golos', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 142.857%;
}

.text-s {
  font-family: 'Golos', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.button-s {
  font-family: 'Golos', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 200%;
  letter-spacing: 0.24px;
  text-transform: uppercase;
}

@mixin h1 {
  font-family: 'Golos', sans-serif;
  font-size: 54px;
  font-style: normal;
  font-weight: 600;
  line-height: 111%;
  text-transform: uppercase;
  @media (max-width: $mediaMobileMax) {
    font-size: 32px;
    line-height: 125%;
  }
}


@mixin h2 {
  font-family: 'Golos', sans-serif;
  font-size: 46px;
  font-style: normal;
  font-weight: 600;
  line-height: 130.435%;
  text-transform: uppercase;
  @media (max-width: $mediaMobileMax) {
    font-size: 28px;
    line-height: 128.571%;
  }
}


@mixin h3 {
  font-family: 'Golos', sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
  @media (max-width: $mediaMobileMax) {
    font-size: 24px;
  }
}


@mixin h4 {
  font-family: 'Golos', sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%;
  @media (max-width: $mediaMobileMax) {
    font-size: 20px;
    line-height: 140%;
  }
}


@mixin text-xl {
  font-family: 'Golos', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
}


@mixin text-l {
  font-family: 'Golos', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

@mixin text-m {
  font-family: 'Golos', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 142.857%;
}

@mixin text-s {
  font-family: 'Golos', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

@mixin button-s {
  font-family: 'Golos', sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 200%;
  letter-spacing: 0.24px;
  text-transform: uppercase;
}
